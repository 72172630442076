.feedback {
    font-family: 'Inter', sans-serif;
    display: flex;
    justify-content: center; /* не менять */
    flex-direction: column;
    min-height: 690px;
  }

  .feedback-container {
    padding: 50px 0;
    display: flex;
    width: 80%;
    align-items: flex-start;
    justify-content:center;
    margin: 0 auto;
  }

  .feedback-text-block, .feedback-form-block {
    width: 50%;
    padding: 20px;
  }
  
  .feedback-text-block h1 {
    margin-bottom: 20px;
  }
  
  .feedback-text-block p {
    margin-bottom: 20px;
  }
  
  .feedback-form-block input[type="text"],
  .feedback-form-block input[type="email"],
  .feedback-form-block textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 20px;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
  
  .feedback-form-block button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: green;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-sizing: border-box;
    font-size: 16px; /* Фиксированный размер шрифта */
    line-height: 1.5; /* Фиксированная высота строки */
    width: auto; /* Автоматическая ширина */
    height: auto; /* Автоматическая высота */
}

.feedback-form-block button:hover,
.feedback-form-block button:active,
.feedback-form-block button:focus {
    padding: 10px 20px; /* Явно указываем те же отступы */
    font-size: 16px; /* Тот же размер шрифта */
    line-height: 1.5; /* Та же высота строки */
    width: auto;
    height: auto;
    outline: none;
    text-decoration: none;
    border: none;
    box-shadow: none;
}

.feedback-form-block button:hover {
    background-color: darkgreen;
}

.feedback-form-block button:active {
    background-color: forestgreen;
}

@media (max-width: 600px) { 
  .feedback-container {
    flex-direction: column;
}

.feedback-text-block, .feedback-form-block {
  width: 100%;
}
  .feedback-text-block h1 {
    font-size: 16px;
  }
  
  .feedback-text-block p {
    font-size: 12px;
  }

  .feedback-text-block h1 {
    font-size: 16px;
  }
  
  .feedback-text-block p {
    font-size: 12px;
  }

  .feedback-form-block input[type="text"],
  .feedback-form-block input[type="email"],
  .feedback-form-block textarea {
    width: 90%;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
.feedback-text-block h1 {
  font-size: 24px;
}

.feedback-text-block p {
  font-size: 16px;
}
.feedback {
  min-height: 200px;
}
}

@media (min-width: 1025px) and (max-width: 1536px) {
.feedback-text-block h1 {
  font-size: 36px;
}

.feedback-text-block p {
  font-size: 20px;
}
.feedback {
  min-height: 522px;
}
}

@media (min-width: 1537px) {
.feedback-text-block h1 {
  font-size: 36px;
}

.feedback-text-block p {
  font-size: 24px;
}
}

/* Основные стили для модального окна */
.modal-overlay {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Стили для содержимого модального окна */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  max-height: 70%;
  display: flex;
  flex-direction: column;
}


.modal-content h2 {
  margin: 0px;
}

.modal-content p {
  margin-bottom: 10px;
}

.apply-button {
  color: white;
  background-color: #4CAF50;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  height: 40px; /* Явно заданный размер */
}

.apply-button:hover {
  background-color: #45a049;
}

.apply-button:hover {
  background-color: #45a049;
}

/* Стили для кнопки закрытия */
.close-button2 {
  align-self: flex-end;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0; /* Убираем padding */
  box-sizing: border-box; /* Обеспечиваем учет всех размеров внутри элемента */
  transition: none; /* Убираем все переходы */
}

/* Убираем эффекты при наведении */
.close-button2:hover {
  background-color: transparent;
  border: none;
  font-size: 24px;
  padding: 0;
  box-sizing: border-box;
  transform: none; /* Убираем любые трансформации */
}

/* Убираем эффекты при нажатии */
.close-button2:active {
  background-color: transparent;
  border: none;
  font-size: 24px;
  padding: 0;
  box-sizing: border-box;
  transform: none; /* Убираем любые трансформации */
}

/* Убираем эффекты при фокусировке */
.close-button2:focus {
  background-color: transparent;
  border: none;
  font-size: 24px;
  padding: 0;
  box-sizing: border-box;
  transform: none; /* Убираем любые трансформации */
  outline: none; /* Убираем обводку при фокусе */
}