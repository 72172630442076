.footer {
    display: flex;
    background-color: #61875E;
    justify-content: space-around;
    padding: 5px 0;
  }
  
  .footer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
  }
  
  .footer-link {
    margin-bottom: 15px;
    color: white;
    text-decoration: none;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
  }

  @media (min-width: 768px) {
    .footer {
        justify-content: space-around;
    }
    .footer-column {
        flex-direction: row;
        align-items: center;
    }
    .footer-link {
        margin: 0 15px;
    }
  }
  
  @media (max-width: 660px) {
    .footer {
      flex-direction: column;
    }
    .footer-column {
        flex-direction: column;
        align-items: flex-start;
    }
    .footer-link {
        margin-bottom: 10px;
        font-size: 16px;
    }
  }
  