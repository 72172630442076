#center-text {          
    display: flex;
    flex: 1;
    flex-direction:column; 
    justify-content: center;
    align-items: center;  
    height:100%;
    
  }
  #chat-circle {
    position: fixed;
    bottom: 20px;
    opacity: 0.85;
    overflow: hidden;
    right: 20px;
    background: #73C66D;
    transition: background-color 0.3s;
    width: 3vh;
    padding: 20px;
    height: 3vh;  
    border-radius: 50%;
    color: white;
    cursor: pointer;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.6), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #chat-circle img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .btn#my-btn {
       background: white;
      padding-top: 13px;
      padding-bottom: 12px;
      border-radius: 45px;
      padding-right: 40px;
      padding-left: 40px;
      color: #5865C3;
  }
  #chat-overlay {
      background: rgba(255,255,255,0.1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: none;
  }
  .chat-input{
    border-radius:30px;  
  }  
.material-icons img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  .chat-box {
    background: #ffffff;
    position:fixed;
    right:20px;
    bottom:20px;  
    width:350px;
    max-width: 85vw;
    max-height: 100vh;
    border-radius:30px;  
  /*   box-shadow: 0px 5px 35px 9px #464a92; */
    /* box-shadow: 0px 5px 35px 9px #ccc; */
  }
  .chat-box-toggle {
    float:right;
    cursor:pointer;
  }
  .chat-box-header {
    background: #5c8d4f;
    height:47px;
    padding-right: 25px;
    padding-left: 25px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    color:white;
    text-align:center;
    font-size:20px;
    padding-top:17px;
  }
  .chat-box-body {
    position: relative;  
    height:370px;  
    height:auto;
    border:1px solid #ccc;  
    overflow: hidden;
  }
  .chat-box-body:after {
    content: "";
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height:100%;
    position: absolute;
    z-index: -1;   
  }
  #chat-input {
    background: #f4f7f9;
    width: 95.7%;
    position: relative;
    height: 47px;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    resize: none;
    outline: none;
    border: 1px solid #ccc;
    color: #000000;
    border-top: none;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    overflow: hidden;
  }
  .chat-input input{
    font-size: 20px;
  }
  .chat-input > form {
      margin-bottom: 0;
  }
  #chat-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
  }
  #chat-input::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
  }
  #chat-input:-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
  }
  #chat-input:-moz-placeholder { /* Firefox 18- */
    color: #ccc;
  }
  .chat-submit {  
    position:absolute;
    padding-top: 5px;
    bottom: 15px;
    right:10px;
    background: transparent;
    box-shadow:none;
    border:none;
    border-radius:50%;
    color:#5c8d4f;
    width:35px;
    height:35px;  
  }
  .chat-logs {
    padding:10px; 
    padding-bottom: 55px;
    height:370px;
    overflow-y:auto;
  }
  .chat-logs p {
    font-size: 14px;
    opacity: 0.8;
  }
  
  .chat-logs::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
  }
  
  .chat-logs::-webkit-scrollbar
  {
      width: 5px;  
      background-color: #F5F5F5;
  }
  
  .chat-logs::-webkit-scrollbar-thumb
  {
      background-color: #5c8d4f;
  }
  
  
  
  @media only screen and (max-width: 500px) {
     .chat-logs {
          height:40vh;
      }
  }
  
  .chat-msg.user > .msg-avatar img {
    width:45px;
    height:45px;
    float:left;
    width:15%;
  }
  .chat-msg.self > .msg-avatar img {
    width:45px;
    height:45px;
    border-radius:50%;
    float:right;
    width:15%;
  }
  .cm-msg-text {
    background:white;
    padding:10px 15px 10px 15px;  
    color:#666;
    max-width:75%;
    float:left;
    margin-left:10px; 
    position:relative;
    margin-bottom:20px;
    border-radius:30px;
  }
  .chat-msg {
    clear:both;    
  }
  .chat-msg.self > .cm-msg-text {  
    float:right;
    margin-right:10px;
    background: #73c66d;
    color:white;
  }
  .cm-msg-button>ul>li {
    list-style:none;
    float:left;
    width:50%;
  }
  .cm-msg-button {
      clear: both;
      margin-bottom: 70px;
  }