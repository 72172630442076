.about-content {
    font-family: 'Inter', sans-serif;
    display:grid;
    flex-direction: column;
    align-items: center;
  }

  /* .container {
    max-width: 1200px;
    margin: 0 auto;
  } */

  .about-section h1 {
    font-size: 20px;
  }

  .contact-section p {
    font-size: 24px;
  }
  
  @media (max-width: 600px) {
    .about-section h1 {
        font-size: 26px;
      }
    
      .contact-section p {
        font-size: 12px;
      }
  }
  
  @media (min-width: 601px) and (max-width: 1024px) {
    .contact-info {
        display: flex;
      }
      .about-section h1 {
        font-size: 26px;
      }
    
      .contact-section p {
        font-size: 16px;
      }
  }
  
  @media (min-width: 1025px) and (max-width: 1536px) {
    .contact-info {
        display: flex;
      }
  }
  
  @media (min-width: 1537px) {
    .team-photo-block {
        height: 200px;
      }
      .team-card {
        width: 250px; 
      }
      .contact-info {
        display: flex;
      }
      .about-section h1 {
        font-size: 48px;
      }
      .contact-section p {
        font-size: 24px;
      }
  }
  
  .team-section {
    display: flex;
    flex-wrap: wrap;
  }
  
  .team-card {
    width: 200px;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .team-photo-block {
    width: 100%;
    height: 100px; 
  }
  
  .team-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-info-block {
    padding: 10px;
  }
  
  .team-info-block h3 {
    margin: 0;
    color: #333;
    font-size: 16px;
  }
  
  .team-info-block p {
    margin: 5px 0;
    color: #666;
    font-size: 12px;
  }
  
  .contact-info {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin: 10px;
  }
  
  .contact-icon {
    margin-right: 10px;
    border-radius: 15px;
    text-align: center;
  }