.navigatorr {
  display: flex;
  flex-wrap: wrap;
  background-color: #61875E;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
  /* left: 0; */
  /* bottom: 0; */
  /* position: absolute; */
}
.spin{
background-image: url('../img/Spin.png'); 
background-size: cover; 
position: absolute;
width: 475.47px;
height: 442.88px;
left: 67px;
top: 549px;
}
.checpoint-block{
  margin-left: -§0vw;
  display: flex;
  height: 60px;
  width: 90%;
  align-items: center; 
  margin-bottom: 10px;
}

.checpoint{
  background-image: url('../img/checkpoint.svg'); 
  background-size: cover; 
  background-repeat: no-repeat; 
  height: 60px;
  width: 60px;
  margin-right: 1vw;
}
.checpoint-text{
  display: flex; /* Flexbox для внутреннего выравнивания */
  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 2.5rem; /* Используем rem для гибкости */
  text-align: left; /* Выравнивание текста влево для чтения */
}
body.lab-page {
  background-image: url('../img/backgraund.png'); 
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center 6.5vh;

  margin-bottom: 15vh;
  max-width: 100vw; /* Ограничиваем ширину до ширины окна */
    overflow-x: hidden; /* Прячем горизонтальную прокрутку */
    padding: 0;
}


.custom-rectangle {
  margin: 40vh auto 0; /* Отступ сверху и центрирование по горизонтали */
  width: 90%; /* Ширина адаптивна, 90% от родительского элемента */
  max-width: 1801px; /* Максимальная ширина */
  max-height: 6304px; /* Ограничение максимальной высоты */
  background-color: rgb(254, 254, 254); /* Цвет фона */
  border-radius: 90px; /* Радиус скругления углов */
  box-sizing: border-box; /* Учитываем padding и border в размерах */

  display: flex; /* Включаем Flexbox */
  flex-direction: column; /* Элементы будут расположены в столбик */
  align-items: center; /* Выравнивание по центру по ширине */
  text-align: center; /* Центрируем текст внутри элементов */
}

.text-block {
  margin-right: 20px;
  margin-top: 55vh;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 3rem; /* Используем rem для гибкости */
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 5vh;
}

.text-2block {
  margin-right: 20px;
  margin-top: 3vh;
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 3rem; /* Используем rem для гибкости */
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 5vh;
}

.block-tech{
  display: flex; /* Включаем Flexbox */
  flex-direction:row; /* Элементы будут расположены в столбик */
  align-items: center; /* Выравнивание по центру по ширине */
  text-align: center; 
  height: 80%;
}

.block-tech-text-right{
  display: flex; /* Включаем Flexbox */
  flex-direction:column; /* Элементы будут расположены в столбик */
  align-items: flex-end; /* Выравнивание по центру по ширине */
  text-align: center; 
  width: 40vw;
  height: 100%;
}
.block-tech-text-left{
  display: flex; /* Включаем Flexbox */
  flex-direction:column; /* Элементы будут расположены в столбик */
  align-items: flex-start; /* Выравнивание по центру по ширине */
  text-align: center; 
  width: 40vw;
  height: 100%;
}

.block-tech-text-left1{
  display: flex; /* Включаем Flexbox */
  flex-direction:column; /* Элементы будут расположены в столбик */
  align-items: center; /* Выравнивание по центру по ширине */
  text-align: center; 
  justify-content: center;
  width: 40vw;
  height: 100%;
}
.example-text-right{

  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem; /* Используем rem для гибкости */
  width: 20vw;
  margin-left: 50px;
  text-align: right; /* Выравнивание текста влево для чтения */
}

.example-text-left{

  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem; /* Используем rem для гибкости */
  width: 20vw;
  text-align: left; /* Выравнивание текста влево для чтения */
}
.example-text-right-top{

  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 1.9rem; /* Используем rem для гибкости */
  width: 20vw;
  font-weight: 600;
  text-align: right; /* Выравнивание текста влево для чтения */
  margin-bottom: 15px;
}
.example-text-left1{

  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem; /* Используем rem для гибкости */
  width: 20vw;
  text-align: left; /* Выравнивание текста влево для чтения */
}
.example-text-left-top1{
  margin-left: 10vw;
  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 2.4rem; /* Используем rem для гибкости */
  width: 30vw;
  font-weight: 600;
  text-align: left; /* Выравнивание текста влево для чтения */
  margin-bottom: 15px;
}
.example-text-left-top{

  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 1.9rem; /* Используем rem для гибкости */
  font-weight: 600;
  width: 20vw;
  text-align: left; /* Выравнивание текста влево для чтения */
  margin-bottom: 15px;
}
.example-print{
  background-image: url('../img/Print_example.png');
  background-position: center;
  background-size: cover; 
  background-repeat: no-repeat; 
  width: 33vw;
  height: 51vh;
}
.print{
  background-position: center;
  background-size: cover; 
  background-repeat: no-repeat; 
  width: 33vw;
  height: 51vh;
}
.example-block{
  height: 60vh;
  width: 95%;
  display: flex; /* Включаем Flexbox */
  flex-direction:row; /* Элементы будут расположены в столбик */
  align-items: center; /* Выравнивание по центру по ширине */
  text-align: center; /* Центрируем текст внутри элементов */
  justify-content: center; /* Центровка по горизонтали */

}
.example{
  background-image: url('../img/3D_example.png');
  background-size: cover; 
  background-repeat: no-repeat; 
  width: 50vw;
  height: 50vh;
}


.carousel-title {
  text-align: center;
  margin-bottom: 70px;
}

.carousel-section1 {
  font-size: 24px;
}
.slider-image {
  width: 100%; /* Изображение будет растягиваться на всю доступную ширину */
  height: auto; /* Высота будет автоматически пропорциональна ширине */
  object-fit: cover; /* Изображение будет покрывать контейнер, обрезаясь по необходимости */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Плавные переходы для анимации */
}

.slider-image:hover {
  transform: scale(1.05); /* Немного увеличиваем изображение при наведении */
  opacity: 0.9; /* Уменьшаем непрозрачность для эффекта наведения */
}
.logo-container2 {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  justify-content: flex-start;  /* Выровнять логотипы по левому краю */
  background-color: #EBF0EB;
  padding: 15px;
  gap: 20px; /* Отступы между элементами */
}

.logo-container2 div {
  flex-shrink: 0; /* Запрещаем сжимать изображения */
}

img {
  height: 80px; /* Установите нужный размер логотипов */
  object-fit: contain; /* Логотипы не будут искажаться */
}
.example-text{

  align-items: center; /* Центрирование по вертикали */
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 1.2rem; /* Используем rem для гибкости */
  width: 30vw;
  margin-left: 50px;
  text-align: left; /* Выравнивание текста влево для чтения */
}

.head-block {
  background-image: url('../img/Group.png');
  background-size: contain;
  background-position: center;
  width: 95vw;
  margin-left: 4vw;
  height: 48.4vw;
  margin-top: 1vh;
  position: absolute;

  display: flex; /* Включаем Flexbox */
  flex-direction: column; /* Элементы будут расположены в столбик */
  align-items: center; /* Выравнивание по центру по ширине */
  text-align: center; /* Центрируем текст внутри элементов */
}

.text-head {
  margin-right: 20px;
  margin-top: 80px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 3rem; /* Используем rem для гибкости */
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
}

.text-2head {
  margin-right: 20px;
  margin-top: 0;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 2.5rem; /* Используем rem для гибкости */
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 30px;
}

.block-text-head {
  margin-right: 20px;
  margin: 10px;
  color: #ffffff;
  font-family: 'Inter', sans-serif;
  font-size: 1.5rem; /* Используем rem для гибкости */
  width: 80%; /* Используем процент для ширины */
  max-width: 517px; /* Ограничиваем максимальную ширину */
  height: auto; /* Убираем жесткую высоту */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem; /* Увеличиваем интервал */
  padding: 1.4rem 2rem;
  box-sizing: border-box;
  border-radius: 28.46px;
  backdrop-filter: blur(17.79px);
  background: rgba(176, 230, 255, 0.28);
}

.head-button {
  margin-right: 20px;
  margin-top: 20px;
  position: relative;
  width: 60%; /* Используем процент для гибкости */
  max-width: 435px; /* Ограничиваем максимальную ширину */
  height: auto; /* Высота подстраивается по содержимому */
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 44px;
  background: rgb(199, 173, 40);
  color: white;
  font-size: 1.8rem; /* Используем rem для гибкости */
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.head-button:hover {
  background-color: rgb(180, 150, 30);
}

/* Медиазапросы для корректного отображения на мобильных устройствах */
@media (max-width: 768px) {
  .text-head {
    font-size: 2.5rem;
  }
  
  .text-2head {
    font-size: 2rem;
  }

  .block-text-head {
    font-size: 1.3rem;
    padding: 1rem;
  }

  .head-button {
    font-size: 1.5rem;
    padding: 0.8rem;
  }
}

  .navigatorr-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 10px;
  }
  
  .navigatorr-link {
    margin-bottom: 15px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 24px;
    font-family: 'Inter', sans-serif;
  }

  @media (min-width: 768px) {
    .navigatorr {
        justify-content: space-around;
    }
    .navigatorr-column {
        flex-direction: row;
        align-items: center;
    }
    .navigatorr-link {
        margin: 0 15px;
    }
  }
  
  @media (max-width: 660px) {
    .navigatorr {
      flex-direction: column;
      position: relative;
    }
    .navigatorr-column {
        flex-direction: column;
        align-items: flex-start;
    }
    .navigatorr-link {
        margin-bottom: 10px;
        font-size: 16px;
    }
  }


  .accordion {
    width: 90%;
    margin: 94px auto;
    font-family: 'Inter', sans-serif;
  }

  .accordion-item {
    /* background-color: #fff; */
    border-bottom: 1.5px solid black;
    /* margin-bottom: 5px; */
  }

  .accordion-t {
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px;
    font-size: 36px;
  }

  .accordion-title {
    font-size: 12px; /* заголовок */
    cursor: pointer;
    background-color: #fff;
    margin: 0;
    padding: 15px 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion-title:hover,
.accordion-title.active {
  background-color: #EBF0EB;
}

.accordion-content {
  display: none; 
  padding: 0px 35px;
  border-top: 0;
}

.accordion-content.active {
  display: block; 
}

.accordion-icon {
  float: right; 
  transition: transform 0.3s ease; 
  font-size: 16px;
}

.accordion-icon.open {
  transform: rotate(180deg); 
}
  
  @media (max-width: 1536px) {
    .text-section {
      padding: 25px 100px 25px 0;
    }
    .content p {
      font-size: 16px;
    }
  }


  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 5vh;
  }
  
  .slider-track {
    width: 80%;
    height: 20vh;
    justify-content: flex-start;
    display: flex;
    gap: 50px;
    transition: transform 0.5s ease;
    align-items: center;
  }
  
  .slider-item {
    flex: 0 0 calc((100% - 100px) / 3); 
    height: 200px;
    border-radius: 24px;
    background: rgb(233, 234, 232);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #333;
    text-align: center;
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure buttons are above slides */
  }
  
  .prev-button {
    left: 3vw;
  }
  
  .next-button {
    right: 3vw;
  }
  
  .slider-button:hover {
    background-color: #f0f0f0;
  }
  .price-card {
    width: 100%;
    max-width: 75rem; /* max-w-3xl */
    margin: 0 auto;
    border-radius: 1rem; /* rounded-xl */
    background: linear-gradient(to bottom right, #f5f5f5, #ffffff); /* светло-серый и белый */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* shadow-lg */
  }
  
  .price-card-content {
    padding: 1.5rem; /* p-6 */
  }
  
  .price-card-title {
    font-size: 1.5rem; /* text-2xl */
    font-weight: bold;
    color: #333333; /* чёрный */
    margin-bottom: 1.5rem; /* mb-6 */
    text-align: center; /* text-center */
  }
  
  .price-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* grid-cols-2 */
    gap: 1rem; /* gap-4 */
  }
  
  @media (min-width: 768px) {
    .price-grid {
      grid-template-columns: repeat(3, 1fr); /* md:grid-cols-3 */
    }
  }
  
  .price-item {
    padding: 1.4rem; /* p-3 */
    border-radius: 1rem; /* rounded-xl */
    background-color: #ffffff; /* белый */
    border: 1px solid #d1d5db; /* светло-серый */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* shadow-sm */
    transition: all 0.3s ease; /* transition-all duration-300 */
  }
  
  .price-item:hover {
    background-color: #f5f5f5; /* светло-серый при наведении */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* hover:shadow-md */
  }
  
  .price-item-icon {
    color: #333333; /* чёрный */
    transition: color 0.3s ease; /* group-hover:text-black transition-colors */
  }
  
  .price-item:hover .price-item-icon {
    color: #000000; /* чёрный при наведении */
  }
  
  .price-item-title {
    font-size: 0.875rem; /* text-sm */
    font-weight: 600; /* font-semibold */
    color: #333333; /* чёрный */
    transition: color 0.3s ease; /* group-hover:text-black */
  }
  
  .price-item:hover .price-item-title {
    color: #000000; /* чёрный при наведении */
  }
  
  .price-item-description {
    font-size: 0.75rem; /* text-xs */
    color: #555555; /* тёмно-серый */
    transition: color 0.3s ease; /* group-hover:text-black */
  }
  
  .price-item:hover .price-item-description {
    color: #333333; /* чёрный при наведении */
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    font-size: 1.5rem;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .modal-form div {
    margin-bottom: 15px;
  }
  .modal-form textarea {

    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 2vw;
    height: 150px; /* Укажите желаемую высоту */
    resize: vertical; /* Позволяет изменять высоту вручную (опционально) */
  }
  
  .modal-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 2vw;
  }


  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .modal-form button {
    background-color: black;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
  }
  
  .modal-form button:hover {
    background-color: #444;
  }
.ss {
  display: flex;
 
}

.ss label {
  margin-right: 10px; /* Отступ между текстом и полем */
  width: 140px;
}